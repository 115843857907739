<template>
  <RsModal
    class="modal-share-link__wrapper"
    @dismiss="closeModal"
  >
    <div>
      <div class="flex justify-between items-end">
        <RsHeadline variant="h2">{{ $t('certificatePage.shareTutorialModalTitle') }}</RsHeadline>
        <IcCloseBlack class="text-rs-primary cursor-pointer" width="24" @click="closeModal"/>
      </div>

      <RsHeadline class="mt-8" variant="h2">
        {{ $t('certificatePage.directLink') }}
      </RsHeadline>

      <p class="mt-4">{{ $t('certificatePage.shareMessage') }}</p>

      <div class="flex mt-10">
        <RsInput id="rs-share-button" :model-value="shareUrl" readonly />
        <RsButton class="h-12" @click="copy">{{ $t('certificatePage.copyButton') }}</RsButton>
      </div>

      <RsButton class="ml-auto mt-8 !px-10" variant="secondary" @click="closeModal">
        {{ $t('common.close') }}
      </RsButton>
    </div>
  </RsModal>
</template>

<script setup lang="ts">
import RsInput from '~/components/common/RsInput.vue'
import { IcCloseBlack } from '~/components/common/icons'

const props = defineProps({
  customerLanguage: {
    type: String,
    default: 'en',
  }
})

const emit = defineEmits(['commit'])
const closeModal = () => emit('commit')

const localePath = useLocalePath()
const route = useRoute()

const shareUrl = computed(() => {
  const slug = route.params.slug
  return `${window.location.origin}${localePath({ path: `/${slug}` }, props.customerLanguage)}`
})

const copy = () => {
  navigator.clipboard.writeText(shareUrl.value)

  const input = document.getElementById('rs-share-button')
  if (!input) {
    return
  }
  input.select()
}
</script>

<style scoped>
.modal-share-link__wrapper {
  @apply block;
}

.modal-share-link__wrapper :deep(.modal__window) {
  @apply px-2 py-1;
}
</style>
